$(document).ready(function(){
  var t;

  $(document).on("click", ".online-auction-btn", function(){
    $('.online_auctions_categories').removeClass('hide');
    $('.auction_notices_categories').addClass('hide');
    $('.sale_ads_categories').addClass('hide');
  });

  $(document).on("click", ".auction-notice-btn", function(){
    $('.online_auctions_categories').addClass('hide');
    $('.auction_notices_categories').removeClass('hide');
    $('.sale_ads_categories').addClass('hide');
  });

  $(document).on("click", ".sales-ad-btn", function(){
    $('.online_auctions_categories').addClass('hide');
    $('.auction_notices_categories').addClass('hide');
    $('.sale_ads_categories').removeClass('hide');
  });

 	$(document).on("click", ".active_search", function(){
    $("#active_search").val(true);
    $('.end_search').removeClass('btn-primary');
    $('.active_search').addClass('btn-primary');
  });

  $(document).on("click", ".end_search", function(){
    $("#active_search").val(false);
    $('.active_search').removeClass('btn-primary');
    $('.end_search').addClass('btn-primary');
  });

  $(document).on("keyup", "#auction_offer", function(){
    if($('#auction_offer').val()){
      $(".auction_offer_button").removeClass("disableattchmentbutton");
    } else{
      $(".auction_offer_button").addClass("disableattchmentbutton");
    }
  });

  $(document).on('click', '.auction_filter_button',  function() {
    var checked = []
    $("input[name='category_search[]']:checked").each(function ()
    {
        checked.push($(this).val());
    });
    clearTimeout(t);
    t = setTimeout(function () {
      $("#loader-image").show();
      $.ajax({
        url: '/search/auction_filter',
        type: 'GET',
        data: {search : $("#auction_search").val(),
               active_search : $("#active_search").val(),
               category_search : checked,
               auction_locations: $("#auction_locations").val(),
               minimum_price: $("#minimum_price").val(),
               maximum_price: $("#maximum_price").val(),
               auction_type: $("#auction_type").val(),
               filter: true}
      });
    }, 500);
  });

  $(document).on("click", "#pills-online-auction-tab", function(){
    $(".online_auction_section").removeClass("hide");
    $(".auction_notice_section").addClass("hide");
    $(".sales_ad_section").addClass("hide");
  });

  $(document).on("click", "#pills-auction-announcement-tab", function(){
    $(".online_auction_section").addClass("hide");
    $(".auction_notice_section").removeClass("hide");
    $(".sales_ad_section").addClass("hide");
  });

  $(document).on("click", "#pills-notice-sale-tab", function(){
    $(".online_auction_section").addClass("hide");
    $(".auction_notice_section").addClass("hide");
    $(".sales_ad_section").removeClass("hide");
  });

  if ($('.pagination').length) {
    $(document).scroll(function() {
      var url = $('.pagination .next_page').attr('href');
      if (url && $(document).scrollTop() > $(document).height() - $(document).height() - 50) {
        $('.pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
    return $(document).scroll();
  }

  $(document).on("click", ".view-more-link", function(){
    $('.view-less').removeClass('hide');
    $('.view-more').addClass('hide');
    $('.view-more-info').removeClass('hide');
  });

  $(document).on("click", ".view-less-link", function(){
    $('.view-less').addClass('hide');
    $('.view-more').removeClass('hide');
    $('.view-more-info').addClass('hide');
  });
});