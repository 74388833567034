// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("cocoon")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('bootstrap')
require('website/bootstrap-select.min')
require('website/popper.min')
require('website/jquery-ui-1.10.1.custom.min')
require('website/jquery.jscroll')
require('website/jquery.timepicker.min')
import I18n from 'i18n-js'
window.I18n = I18n
require('i18n/translations')
// require('website/jquery.keyboard')
require('bootstrap-datepicker')
require('front/auction')
require('website/maps')
require("packs/select2setup")
require('website/acommon')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);

require("trix")
require("@rails/actiontext")