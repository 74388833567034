///select2setup.js
import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'

/// if you don't have turnbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => { 
$(document).ready(function(){
  $('.select2').select2({
    // tags: true,
    tokenSeparators: [',', ' ']
  });

  $(document).on("click", ".view-more-link", function(){
    $('.select2').select2({
      tokenSeparators: [',', ' ']
    });
  });

  $('.clear_auction_locations').click(function() {
    $('#auction_locations').val(null).trigger('change');
  });
});

$(() => {
  $('.select2').select2();
});