const countdownElement = document.getElementById("countdown");

document.addEventListener("DOMContentLoaded", function() {
  // Get the end time from the server
  const endTime = new Date($("#remaining_time").val());

  function updateCountdown() {
    const now = new Date();
    const timeRemaining = endTime - now;

    if (timeRemaining <= 0) {
      document.getElementById('countdown').innerHTML = "Time's up!";
      clearInterval(timerInterval);
    } else {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      // Format hours, minutes, and seconds to always have two digits
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      var countdown = document.getElementById('countdown')
      if(countdown){
        document.getElementById('countdown').innerHTML =
        `${days} days ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }
    }
  }

  // Update the countdown every second
  const timerInterval = setInterval(updateCountdown, 1000);

  // Initialize countdown display
  updateCountdown();
});

$(document).ready(function(){

  $(document).on("change", "#auction_start_time_start_hours, #auction_start_time_start_minutes, #post_time_end_hours, #post_time_end_minutes", function(){
    var date1 = $("#auction_auction_date").val();
    var date2 = $("#auction_publication_date").val();
    var hours1 = $("#auction_start_time_start_hours").val();
    var hours2 = $("#post_time_end_hours").val();
    var minutes1 = $("#auction_start_time_start_minutes").val();
    var minutes2 = $("#post_time_end_minutes").val();

    // Convert times to minutes since midnight
    var totalMinutes1 = hours1 * 60 + minutes1;
    var totalMinutes2 = hours2 * 60 + minutes2;

    // Compare the two times
    if ((date1 == date2) && (totalMinutes1 < totalMinutes2)) {
      $("#scheduled_date_notice").html("<div class='alert alert-warning alert-dismissible'>Publication start time is not later than end time.<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
      $(".save_unfinished_auction_publication_date").addClass("disableattchmentbutton");
      $(".save_auction_publication_date").addClass("disableattchmentbutton");
    } else{
      $("#scheduled_date_notice").html("");
      $(".save_unfinished_auction_publication_date").removeClass("disableattchmentbutton");
      $(".save_auction_publication_date").removeClass("disableattchmentbutton");
    }
  });

  $(document).on("click", ".active_bidder", function(){
    $('.active_bidder').addClass('btn-primary');
    $('.active_seller').removeClass('btn-primary');
    $("#user_role").val("BIDDER");
    $("#customer_type_details").removeClass("hide");
    $( "#company_info" ).addClass( "hide" );
    $( "#basic_info" ).addClass( "hide" );
    $( "#company_other_info" ).addClass( "hide" );
    $( "#private_info" ).removeClass( "hide" );
  });

  $(document).on("click", ".active_seller", function(){
    $('.active_seller').addClass('btn-primary');
    $('.active_bidder').removeClass('btn-primary');
    $("#user_role").val("SELLER");
    $("#customer_type_details").addClass("hide");
    $( "#company_info" ).removeClass( "hide" );
    $( "#basic_info" ).removeClass( "hide" );
    $( "#company_other_info" ).removeClass( "hide" );
    $( "#private_info" ).addClass( "hide" );
  });

  $(document).on("click", "#add_new_ad_attachment", function () {
    setDatePicker();
    var other_task = $("#ad_attachments").clone();

    var last_task = $("#existing_ad_attachments .added_ad_attachment_step:last").attr('id');
    if (last_task) {
        var index = parseInt(getId(last_task)) + 1;
    } else {
        var index = 1;
    }

    other_task = other_task.html().replace(/replace_random_id/g, index).replace(/replace_param/g, "ad_attachment_attachment");
    $("#other_ad_attachments").append(other_task);
  });

  $(document).on("click",".remove_ad_attachment", function() {
      var id = getId(this.id);
      $("#parent_element_id_"+ id).remove();
  });

  $('#flash_msg').delay(5000).fadeOut(5000);

  $(document).on('keyup', '.bid_offer',  function() {
    var id = getId(this.id);
    var bid_minimum_price = $("#bid_minimum_price_" + id).val();
    var bid_offer = $("#bid_offer_amount_"+id).val();
    setTimeout(function () {
      if(checkNumericValue(bid_offer) < checkNumericValue(bid_minimum_price)){
        $("#auction_offer_notice_"+id).html("<div class='alert alert-warning alert-dismissible'>we cannot add the value below the Minimum increase set up.<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
        $("#bid_offer_button_"+id).addClass("disableattchmentbutton");
      } else {
        $("#auction_offer_notice_"+id).html("");
        $("#bid_offer_button_"+id).removeClass("disableattchmentbutton");
      }
    }, 1000);
  });

  $(document).on('keyup', '#offer_maximum_bid',  function() {
    var maximum_bid = $("#offer_maximum_bid").val();
    var offer_increment_limit = $("#offer_increment_limit").val();
    var bid_vat_type = $("#vat_rate").val();
    var highest_bid = $("#auction_highest_bid").val();
    var auction_minimum_increase = $("#auction_minimum_increase").val();
    setTimeout(function () {
      if(checkNumericValue(highest_bid) > checkNumericValue(maximum_bid)){
        $("#maximum_auction_notice").html("<div class='alert alert-warning alert-dismissible'>Maximum price must be greater than the current highest offer.<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
        $(".set_auto_raiser_btn").addClass("disableattchmentbutton");
      }else{
        $("#maximum_auction_notice").html("");
        $(".set_auto_raiser_btn").removeClass("disableattchmentbutton");
      }
    }, 1000);
    if (bid_vat_type == "0,0"){
      var price_with_vat = checkNumericValue(maximum_bid)
    }else{
      var price_with_vat = checkNumericValue(maximum_bid) * (1 + (checkNumericValue(bid_vat_type) / 100))
    }
    $("#bid_maximum_price").val(checkNumericValue(price_with_vat));

    if(maximum_bid && offer_increment_limit){
      $(".set_auto_raiser_btn").removeClass("disableattchmentbutton");
    }else{
      $(".set_auto_raiser_btn").addClass("disableattchmentbutton");
    }
  });

  $(document).on('keyup', '#offer_increment_limit',  function() {
    var maximum_bid = $("#offer_maximum_bid").val();
    var offer_increment_limit = $("#offer_increment_limit").val();
    var bid_vat_type = $("#vat_rate").val();
    var highest_bid = $("#auction_highest_bid").val();
    var auction_minimum_increase = $("#auction_minimum_increase").val();
    var auction_maximum_increase = $("#auction_maximum_increase").val();
    console.log(checkNumericValue(auction_maximum_increase) < checkNumericValue(offer_increment_limit))
    setTimeout(function () {
      if(checkNumericValue(auction_maximum_increase) < checkNumericValue(offer_increment_limit)){
        $("#maximum_increase_notice").html("<div class='alert alert-warning alert-dismissible'>Increment limit must be less than the maximum increase value.<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
        $(".set_auto_raiser_btn").addClass("disableattchmentbutton");
      }else{
        $("#maximum_increase_notice").html("");
        $(".set_auto_raiser_btn").removeClass("disableattchmentbutton");
      }

      if(checkNumericValue(auction_minimum_increase) > checkNumericValue(offer_increment_limit)){
        $("#minimum_auction_notice").html("<div class='alert alert-warning alert-dismissible'>Increment limit must be greater than the minimum increase value.<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
        $(".set_auto_raiser_btn").addClass("disableattchmentbutton");
      }else{
        $("#minimum_auction_notice").html("");
        $(".set_auto_raiser_btn").removeClass("disableattchmentbutton");
      }
    }, 1000);
    if(maximum_bid && offer_increment_limit){
      $(".set_auto_raiser_btn").removeClass("disableattchmentbutton");
    }else{
      $(".set_auto_raiser_btn").addClass("disableattchmentbutton");
    }
  });


  $(document).on("click", "#basic_info", function(){
    $('.basic_info_check').addClass('active');
    $('.other_offices_check').removeClass('active');
    $( "#basic_info_details" ).removeClass( "hide" )
    $( "#other_office_details" ).addClass( "hide" )
  });

  $(document).on("click", "#other_offices", function(){
    $('.other_offices_check').addClass('active');
    $('.basic_info_check').removeClass('active');
    $( "#basic_info_details" ).addClass( "hide" )
    $( "#other_office_details" ).removeClass( "hide" )
  });

  $(document).on("click", ".show_drop_down_menu", function(){
    var parentEl = $(this).parent().closest('.drop_down_data_list');
    $(parentEl).find('.set_title_name').toggleClass("hide");
    $(parentEl).find('.show_upside_arrow').toggleClass("hide");
    $(parentEl).find('.display_drop_down_data').toggleClass("hide");
  });

  if ($('.pagination').length) {
    $(document).scroll(function() {
      var url = $('.pagination .next_page').attr('href');
      if (url && $(document).scrollTop() > $(document).height() - $(document).height() - 50) {
        $('.pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
    return $(document).scroll();
  }

  if ($('.first_pagination').length) {
    $(document).scroll(function() {
      var url = $('.first_pagination .next_page').attr('href');
      if (url && $(document).scrollTop() > $(document).height() - $(document).height() - 50) {
        $('.first_pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
    return $(document).scroll();
  }

  if ($('.second_pagination').length) {
    $(document).scroll(function() {
      var url = $('.second_pagination .next_page').attr('href');
      if (url && $(document).scrollTop() > $(document).height() - $(document).height() - 50) {
        $('.second_pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
    return $(document).scroll();
  }

  if ($('.third_pagination').length) {
    $(document).scroll(function() {
      var url = $('.third_pagination .next_page').attr('href');
      if (url && $(document).scrollTop() > $(document).height() - $(document).height() - 50) {
        $('.third_pagination').text("Please Wait...");
        return $.getScript(url);
      }
    });
    return $(document).scroll();
  }

  $(document).on("click", ".show_detail_drop_down_menu", function(){
    var parentEl = $(this).parent().closest('.drop_down_data_list');
    var contractor_type = $(parentEl).find('.set_title_name').siblings(".hide").text().replace(/\n/g, '');
    if (contractor_type == "SUB CONTRACTOR"){
      $("#project_contractor_type").val(1);
    }else{
      $("#project_contractor_type").val(0);
    }
    $(parentEl).find('.set_title_name').toggleClass("hide");
    $(parentEl).find('.show_upside_arrow').toggleClass("hide");
    $(parentEl).find('.show_drop_down_data').toggleClass("hide");
    $(parentEl).find(".show_detail_drop_down_menu").toggleClass("up-arrow");
    $(parentEl).find(".show_detail_drop_down_menu").toggleClass("down-arrow");
  });

  $(document).on("click", ".openCloseDetails", function(){
    var toggle_id = $(this).data('show_modal_id');
    $(this).parent().parent().find("#"+toggle_id).toggleClass("hide");
    $(this).find(".show_selected_detail_arrow").toggleClass("hide");
  });

  $(".addThousandSeparators").focusout(function() {
    $(this).val(addThousandSeparators($(this).val()));
  });

  $("#presentation_text").on('keyup',function(){
    $(this).css('height','auto');
    $(this).height(this.scrollHeight);
  })

  $(document).on('click', '#save_send_feedback_modal_form_btn', function(){
    var browser_data;
    var browser_parser = new UAParser();
    var browser_global_data = browser_parser.getResult();
    var screen_size;

    if(window){
      if(window.screen){
        screen_size = window.screen.width + 'x' + window.screen.height
      }
    }

    browser_data = {
                    'browser_name': browser_global_data.browser.name,
                    'browser_version': browser_global_data.browser.version,
                    'os_name': browser_global_data.os.name,
                    'os_version': browser_global_data.os.version,
                    'screen_size': screen_size,
                    'device_type': browser_global_data.device.type
                  }

    var data = JSON.stringify(browser_data);
    var redirection_url = window.location.href;
    $.ajax({
      url: '/send_feedback_developers_form',
      type: 'POST',
      data: {browser_data : data, current_page_url : redirection_url}
    });
  });

  common_events();

  $(document).on("change", ".check_feedback_phase_attachment_type", function(){
    var phase_type = $(this).val();
    $('.attach_current_page_location').addClass('hide');
    $('.attach_feedback_resources').addClass('hide');
    if (phase_type == "YES"){
      $('.attach_current_page_location').removeClass('hide');
    }else{
      $('.attach_feedback_resources').removeClass('hide');
    }
  });

  $(document).on("change", ".copy_same", function(){
    $('.' + $(this).data('class_name')).val($(this).val());
    if ($(this).data('trigger_class_name')){
      $('.' + $(this).data('trigger_class_name')).trigger('change');
    }
  });

  $(document).on("click", ".unclickable", function(){
    return false;;
  });

  $(document).on("change", ".checkFile", function(){
    var reader = new FileReader();
    var fileField = $(this);
    var file = $(fileField)[0].files[0];

    reader.onload = function() {
      dataUrl = reader.result;
      checkFileIcon = $(fileField).closest('.checkFileParent').find('.checkFileIcon');
      $(checkFileIcon).removeClass('hide');
      var checkLink = $(checkFileIcon).find('a');
      $(checkLink).attr('download', file.name);
      $(checkLink).attr('href', dataUrl);
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  });
  
  $(document).on("click", ".hard_return_back", function(){
    redirectTo(window.location.href);
  });

  $(document).on("click", ".make_request", function(){
    var url = $(this).data('request_url');
    var method_type = $(this).data('method_type');
    var t;
    clearTimeout(t);
    t = setTimeout(function () {
      $.ajax({
        url: url,
        type: method_type
      });
    }, 100);
  });

  $(document).on("change", ".media-file-upload", function(){
    var id = getId(this.id);
    var reader = new FileReader();
    var parentEl = $(this).closest('.file_attachments');
    var ele = $(parentEl).find('.preview_attachments');

    if ($(parentEl).find(".saved_media_file").length > 0){
      $(parentEl).find(".destroy-media-file").val("0");
    }
    removePreviewFileEle = "<a class='remove-file remove-media-file dib' href='javascript:void(0);'><div class='remove-preview-file remove'><i class='fa fa-times'></i></div></a>";

    preview_file_ele = "<li class='form-group'><a href='javascript:void(0);'>" + this.files.item(0).name + "</a> " + removePreviewFileEle + "</li>";
    $(ele).find("li").addClass("hide");
    $(ele).append(preview_file_ele);
  });

  $(document).on("click", ".remove-media-file", function(){
    var parentEl = $(this).closest('.file_attachments');
    $(parentEl).find(".media-file-upload").val('');
    if ($(parentEl).find(".saved_media_file").length > 0){
      $(parentEl).find(".destroy-media-file").val("1");
    }
    $(parentEl).find("li").addClass("hide");
  });

  $(document).on('click', '.checkMandatory', function(e){
    var form = $(this).closest('form');
    var mandatory_fields = $(form).find('.mandatory_field')
    var isValid = checkIfAllFieldsHaveValues(mandatory_fields);
    if (!isValid){
      e.preventDefault();
      $(mandatory_fields).each(function() {
        custom_validation(this);
      });
      $(form).find(".has-error").find('input').focus();
      $('#loader-image').hide();
    }
  });

  $(document).on("change keyup select", ".mandatory_field", function(){
    custom_validation(this);
  });

  $('#display_time_info').on('cocoon:after-insert', function(e, ad_display_time) {
    var new_index  =$(ad_display_time).find('.display_date').attr('id').split('auction_ad_display_times_attributes_')[1].split('_display_date')[0];
    ad_display_time.find('.display_end_time').change(function() {
      var start_time = $("#auction_ad_display_times_attributes_" + new_index + '_start_hour').val();
      var end_time = $("#auction_ad_display_times_attributes_" + new_index + '_end_hour').val();
      st = minFromMidnight(start_time);
      et = minFromMidnight(end_time);
      if(start_time > end_time){
        $("#auction_display_date_notice").html("<div class='alert alert-warning alert-dismissible'>End time must be bigger then Start time<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
        $(".checkMandatory").addClass("disableattchmentbutton");
      }else{
        $(".checkMandatory").removeClass("disableattchmentbutton");
        $("#auction_display_date_notice").html("");
      }
    });
  });

  // scroll listing
  $('.scroll').jscroll({
    loadingHtml: '<img src="/assets/loader-small.gif" alt="Loading" />',
    autoTrigger: true,
    padding: 20,
    nextSelector: 'a.jscroll-next:last',
    callback: common_events
  });

  // open remax email modal
  $(document).on('input propertychange paste click', '#user_email',  function() {
    userEmail = $(this).val();
    if (validateEmail(userEmail)) {
      $("#remaxEmailbtn").attr('disabled', false);
    }else{
      $("#remaxEmailbtn").attr('disabled', true);
    }
  })

  if ($("#user_email").val() != ""){
    $("#remaxEmailbtn").attr('disabled', false);
  }

  $(document).on('click', '#remaxEmailbtn',  function() {
    userVal = $('#user_email').val();
    msgText = $("#old_message").val();
    msgText = msgText.replace("userEmail", userVal);
    msgText = msgText.replace(/^\s+|\s+$/gm,'');
    $("#message").html(msgText);
  })

  if ($("#steps").length > 0){
    var total_step = $("#steps").find($("input")).length/2 + 3;
    $(document).on("click", "#add_new_step",  function() {
      var count = ++total_step;
      $('#input_add_step').append('<div class="added_step" id="added_step_wrapper_' + count + '"><div class="row row7"><div class="col-12 col-sm-3 form-group pt-md-2">STEP '+ count +' </div><div class="col-12 col-sm-4 form-group"><input type="text" id="input_add_year_'+count+'" name="setting[step_'+ count +'][year]" class="form-control" placeholder = "Year"/></div><div class="col-12 col-sm-4 form-group"><input type="text" id="input_add_days_'+count+'" name="setting[step_'+ count +'][days]" class="form-control" placeholder = "days"/></div><div class="col-12 col-sm-1 pt-md-2 form-group"><a href="javascript:void(0)" class="addLink remove_fields dynamic remove_step" id="remove_step_' + count + '"><i class="removeIcon"></i>Remove</a></div></div></div>')
    });
    $(document).on("click",".remove_step", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_step_wrapper_"+ id).remove();
    });
  }

  if ($("#price_steps").length > 0){
    var total_step = $("#price_steps").find($("input")).length/3;
    $(document).on("click", "#add_new_price_step",  function() {
      $("#remove_price_step_div_"+ total_step).addClass("hide");
      if(total_step == 0){
        var min_price = 0;
      }else{
        var min_price = $("#input_add_max_price_"+total_step).val();
      }
      var count = ++total_step;
      $('#input_add_price_step').append('<div class="added_price_step" id="added_price_step_wrapper_' + count + '"> <div class="row row7 pt-3"> <div class="col-12 text-18 fw-bold">PRICE STEP '+ count +'</div> <div class="col-12 col-sm-4 mb-3"><div class="inputOuterText"> <input type="text" id="input_add_min_price_'+count+'" name="price_list[price__'+ count +'][min_selling_price]" value='+ min_price +' class="form-control addThousandSeparators text-center" placeholder = "VAT FREE ITEM SELLING PRICE[MIN.]"/> <div class="textIconOuter">EUR</div></div></div> <div class="col-12 col-sm-4 mb-3"><div class="inputOuterText"> <input type="text" id="input_add_max_price_'+count+'" name="price_list[price__'+ count +'][max_selling_price]" class="form-control addThousandSeparators text-center" placeholder = "VAT FREE ITEM SELLING PRICE[MAX.]"/><div class="textIconOuter">EUR</div></div></div> <div class="col-12 col-sm-4 mb-3"><div class="inputOuterText"> <input type="text" id="input_add_ad_price_'+count+'" name="price_list[price__'+ count +'][ad_price]" class="form-control addThousandSeparators text-center" placeholder = "AD PRICE[VAT 0%]"/><div class="textIconOuter">EUR</div></div></div></div><div class="row"><div class="col-12 col-sm-9"></div><div class="col-12 col-sm-3 mb-3 text-end"> <button class="btn btn-md btn-dark remove_price_step" id="remove_price_step_' + count + '">DELETE TIER</button></div></div> </div>')});
    $(document).on("click",".remove_price_step", function() {
      var idArr = this.id.split("_");
      var id = idArr[idArr.length - 1];
      $("#added_price_step_wrapper_"+ id).remove();
    });
  }

  // order email
  $(function(){
    var remaxrecipient = $('#input_remax_recipient');
    // var count = $('#input_remax_recipient p').size();
    $(document).on("click", "#add_recipient_for_remax_email",  function() {
      $('<p><input type="email" id="recipient_email" name="recipient_email['+ count +']" class="form-control"/><a href="#" id="removeremaxinput">Remove</a></p>').appendTo(remaxrecipient);
      count++;
      return false;
    });
    $(document).on("click","#removeremaxinput", function() {
      if( count > 1 )
        {
          $(this).parents('p').remove();
          count--;
        }
      return false;
    });
  });

  // check for valid hours, minutes
  $(document).on( 'change', ".time_hour",function(){
    if (!($(this).val() >= parseInt("00") && $(this).val() <= 23)){
      $(this).val("");
    }
  });

  $(document).on( 'change', ".time_minute",function(){
    if (!($(this).val() >= parseInt("00") && $(this).val() <= 59)){
      $(this).val("");
    }
  });

  $(document).ready(function () {
    setTimeout(function () {
      $(".stepBtnSticky").css('height', $('.stepBtnSticky').height());
      $(".stepBtnSticky").css('position', 'sticky');
    }, 500);  
  });
  
  $(document).ready(function () {
    setTimeout(function () {
      $(".stepBtnStickymanage").css('height', $(window).height());
      $(".stepBtnStickymanage").css('position', 'sticky');
    }, 500);
  });

  $(document).on('click', '.auction_offer_button',  function() {
    var auction_offer = $("#auction_offer").val();
    var highest_ad_bid = $("#highest_ad_bid").val();
    var minimum_price = $("#minimum_price").val();
    var maximum_price = $("#maximum_price").val();
    var total_min_price = checkNumericValue(highest_ad_bid) + checkNumericValue(minimum_price)
    var total_max_price = checkNumericValue(highest_ad_bid) + checkNumericValue(maximum_price)
    if(checkNumericValue(auction_offer) < checkNumericValue(highest_ad_bid)){
      $("#ad_offer_notice").html("<div class='alert alert-warning alert-dismissible'>Highest bid for this offer is "+highest_ad_bid+". You cannot add less than that.<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
      $(".auction_offer_button").addClass("disableattchmentbutton");
    }else if(checkNumericValue(auction_offer) < checkNumericValue(total_min_price)){
      $("#ad_offer_notice").html("<div class='alert alert-warning alert-dismissible'>MINIMUM INCREASE IS <b>"+minimum_price+" EUR</b><button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
      $(".auction_offer_button").addClass("disableattchmentbutton");
    }else if(checkNumericValue(auction_offer) > checkNumericValue(total_max_price)){
      $("#ad_offer_notice").html("<div class='alert alert-warning alert-dismissible'>MAXIMUM INCREASE IS <b>"+maximum_price+" EUR</b><button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>")
      $(".auction_offer_button").addClass("disableattchmentbutton");
    }else{
      $("#ad_offer_notice").html("");
      $("#auction_offer_button").removeClass("disableattchmentbutton");
      setTimeout(function () {
        $("#loader-image").show();
        $.ajax({
          url: '/make_an_offer',
          type: 'POST',
          data: {auction_id : $("#auction_id").val(), offer : auction_offer}
        });
      }, 500);
    }
  });
  
});


function minFromMidnight(tm){
  var ampm= tm.substr(-2)
  var clk = tm.substr(0, 5);
  var m  = parseInt(clk.match(/\d+$/)[0], 10);
  var h  = parseInt(clk.match(/^\d+/)[0], 10);
  h += (ampm.match(/pm/i))? 12: 0;
  return h*60+m;
}

function custom_validation(element){
  var form = $(element).closest('form');
  if ($(element).val().length > 0){
    remove_validation_text(element);
  }
  else{
   $(element).parent().closest('div').addClass('has-error');
   $(element).parent().closest('div').css("position", "relative");
   $(element).parent().closest('div').find('small:eq(0)').remove();
   $(element).parent().closest('div').find('i:eq(0)').remove();
   $(element).parent().closest('div').append('<i class="form-control-feedback fa fa-remove" style="top: 0px; right: 5px;"></i>'); 
   $(element).parent().closest('div').append('<small class="help-block text-red">'+I18n.t("js.general.task.required")+'</small>');
  }
  if ($(form).find(".has-error").length > 0){
    if ($(form).find("button[type='submit']").hasClass("submit_new_offer")){
      $(form).find('#purchaseofferPartLi4').trigger("click");
      $(form).find("button[type='submit']").prop('disabled',true);
    }
    else{
      $(form).find("button[type='submit']").prop('disabled',true);
    }
  }
  else{
    $(form).find("button[type='submit']").prop('disabled',false);
  }
}

function addThousandSeparators(nStr) {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ' ' + '$2');
  }
  return x1 + x2;
}

function setScrollPosition(selectedId) {
  $('html,body').animate({scrollTop: ($("#" + selectedId).offset().top - 2) }, 'slow');
}

function slideContent(slideFrom){
  if ($("#open_card_form").val() != 'true') {
    $('#mainWrapper').show('slide', { direction : slideFrom}, 1000);
  }
}

function fadeOutFlashMessage(id){
  $("#" + id).delay(3000).fadeOut(500);
}

function getId(id){
  var idArr = id.split("_");
  return idArr[idArr.length - 1];
}

function setFlashMessage(parentId, flashId, msg, fadeOut, animate){
  $("#" + parentId).html(getFlashMessageEle(msg, flashId));
  if (animate != false){
    SetFocusOnTopOfPage()
    //$('html, body').animate({scrollTop:$('#' + parentId).position().top}, 'slow');
  }
  if (fadeOut != false) {
    fadeOutFlashMessage(flashId);
  }
}

function getFlashMessageEle(msg, flashId){
  return "<div id='" + flashId + "' class='alert alert-warning alert-dismissible' role='alert'>" + msg + "<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button></div>"
}

function common_events(){
  $( '#filterFormId' ).on( 'change', 'select', function(){
    $("#loader-image").show();
    // $( '#filterFormId' ).submit();
    $( '#filter_save_button' ).click();
  });

  $(document).on('change', '.filter_check_box', function(){
    $("#loader-image").show();
    $(this).closest('form').submit();
  });

  $('.fileUploadMedia').on( 'change', function(){
    if (this.files && this.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.preview_file_box').html('<img src='+e.target.result+'>');
      }
      reader.readAsDataURL(this.files[0]);
    }
  });

  if ($("#pc_browser").val() != 'true') {
    $('.virtualKeyboard').attr('readonly', true);
  }

  $("#flash_msg").delay(3000).fadeOut(500);

  $(".filter-btn-cls, #filter-btn, #saveMedia, #saveMediaNext").click(function(){
    $("#loader-image").show();
  });
  
  $(document).on("click", ".pre-icon-load", function(){
    $(".pre-icon").show();
  });

  $(document).on("click", ".pre-icon-load-remote", function(){
    $(".pre-icon").show();
  });

  $(document).on("submit", ".pre-load-form", function(){
    $(".pre-icon").show();
  });

  $(document).on("click", ".navbar-toggle", function(){
    $(this).toggleClass('active');
    $('.navbar-collapse').toggleClass('active');
    $('.menuOverlay').toggleClass('active');
  });
  $(document).on("click", ".menuOverlay", function(){
    $(this).removeClass('active');
    $('.navbar-collapse').toggleClass('active');
    $('.navbar-toggle').toggleClass('active');
  });

  $(document).on('change', '.media_file', function(e){
    var parentEl = $(this).closest('.parent');
    $.each(this.files, function() {
      showMediaFilename(this, parentEl);
    });
  });
}

function showMediaFilename(file, parentEl){
  var reader = new FileReader();
  reader.onload = function(e) {
    $(parentEl).find('.media_attachment_name').val(file.name);
  }
  reader.readAsDataURL(file);
}

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function showFilename(file, ele) {
  var reader = new FileReader();
  reader.onload = function(e) {
    $(ele).val(file.name);
  }
  reader.readAsDataURL(file);
}

function redirectTo(url, params){
  $.each(params, function(index, value){
    url = url + value;
  });
  window.location.replace(url);
}

function checkIfAllFieldsHaveValues(elements) {
  var isValid = true;
  $(elements).each(function() {
    if ( !$(this).prop('disabled') && !$(this).prop('readonly') && $(this).val() === '' )
      isValid = false;
  });
  return isValid;
}

function SetFocusOnTopOfPage(){
  $(".modal").animate({ scrollTop: 0 }, "slow");
}

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

function checkIfAllFieldsHaveUniqueValues(elements) {
  var isValid;
  var values = [];
  $(elements).each(function() {
    if ( $(this).val() != '' ) {
      values.push($(this).val())
      var check_valid = true;
      for (i=0; i<values.length; i++) {
        for (j=i+1; j<values.length; j++) {
          if (values[i]==values[j]) {
            isValid = false;
            var first_ele = $(elements)[i];
            var second_ele = $(elements)[j];
            custom_uniquness_validation(first_ele, second_ele);
            check_valid = false;
            break;
          }
        }
      }
      if (check_valid){
        var class_name = $(this).attr("data-unique");
        $('.'+class_name).parent().closest('div').removeClass('has-error');
        $('.'+class_name).parent().closest('div').find('small:eq(0)').remove();
        $('.'+class_name).parent().closest('div').find('i:eq(0)').remove();
      }
    }
  });
  return isValid;
}

function isAnyModalOpen() {
  return $('.modal.in').length > 0;
}

function display_validation_text(element,msg){
  $(element).parent().closest('div').addClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
  $(element).parent().closest('div').append('<i class="form-control-feedback fa fa-remove" style="top: 0px;"></i>'); 
  $(element).parent().closest('div').append('<small class="help-block">'+msg+'</small>');
}

function remove_validation_text(element){
  $(element).parent().closest('div').removeClass('has-error');
  $(element).parent().closest('div').find('small:eq(0)').remove();
  $(element).parent().closest('div').find('i:eq(0)').remove();
}

function checkIfSumEqualToOne(elements){
  var isValid = true;
  var total_fraction = 0;
  $(elements).each(function() {
    var fraction = $(this).val();
    if (fraction.match('/') != null){
      var split = fraction.split('/');
      var result = parseInt(split[0], 10) / parseInt(split[1], 10);
      total_fraction += parseFloat(result)
    }else{
      total_fraction += (parseFloat(fraction))
    }
  });
  if (total_fraction == 1) {
    isValid = true;
  }
  else{
    isValid = false; 
  }
  return isValid;
}

function checkIfPerSumEqualToOne(elements){
  var isValid = true;
  var total_percentage = 0;
  $(elements).each(function() {
    if ( $(this).val() != '' ) {
      total_percentage += parseFloat($(this).val())
    }
  });
  if (total_percentage == 100) {
    isValid = true;
  }
  else{
    isValid = false; 
  }
  return isValid;
}

function checkIsNaNVal(val){
  val = (isNaN(val)) ? 0 : val;
  return val;
}

function checkNumericValue(number, decimal_points = 2) {
  if (number){
    return parseFloat(parseFloat(number.toString().replace(' ', '').replace(",", ".")).toFixed(decimal_points));
  }
  else{
    return 0;
  }
}

function show_formatted_number(number, decimal_points = 2, with_space = true){
  if (number){
    number = checkNumericValue(number);
    var parts = number.toString().split(".");
    if (with_space){
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return parts.join(",");
  }
  else{
    0
  }
}

function commonAddOrRemoveHideClass(check_object, match_value, type){
  var parentEl = $(check_object).closest(".parent_hide_show_class");
  var childEl = $(parentEl).find(".child_hide_show_class");
  if ($(check_object).val() == match_value){
    if (type == "hide_fields"){
      $(childEl).removeClass("hide");
    }
    if (type == "disable_fields"){
      $(childEl).find('input').attr("disabled", false);
    }
  }
  else{
    if (type == "hide_fields"){
      $(childEl).addClass("hide");
    }
    if (type == "disable_fields"){
      $(childEl).find('input').attr("disabled", true);
      $(childEl).find('input').val("");
    }
  }
}

function modify_next_elements(){
  var elements = $(".jscroll-inner")
  $(elements).each(function() {
    var added = $(this).find('.jscroll-added')
    $(this).append($(added).children('.col-12'))
    $(added).children('.col-12').remove()
    $(this).append($(added))
  });
}

if ($(".remove_fields").length == 1){
  $(".remove_fields").addClass("hide");
  }
else if ($(".remove_fields").length >= 1){
  var remove_fields  = document.getElementsByClassName("remove_fields")
  remove_fields[0].className = remove_fields[0].className.replace(/\bremove_fields\b/g, "remove_fields hide")
  }
else{
  if ($(".remove_fields").hasClass("hide")){
  ($(".remove_fields").removeClass("hide"));
  }
}

$(function() {
  $("#imageListId").sortable({
    update: function(event, ui) {
      getIdsOfImages();
    } //end update
  });
});

function getIdsOfImages() {
  var auction_id = $("#auction_id").val();
  var values = [];
  $('.listitemClass').each(function(index) {
    values.push($(this).attr("id").replace("imageNo", ""));
  });

  $.ajax({
    url: '/admin/auctions/'+ auction_id +'/reorder_auction_images',
    type: 'GET',
    data: {id: auction_id, child_ids: values}
  });
}

function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

function setDatePicker() {
  var locale = document.documentElement.lang || 'en';
  $('.datepicker').datepicker({
    format: 'dd.mm.yyyy',
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    orientation: 'bottom',
    weekStart: 1,
    language: locale,
    startDate: '0d'
  });
}